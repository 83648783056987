import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"

import {MainList} from '../components/utils.js'

const About = ({ data }) => {

	const totalCount = data.projects.totalCount;
	const totalTalk = data.talks.totalCount;
	return (
		<Layout location="about">
			<div className="container">
				<MainList about={true} total={totalCount} totalTalk={totalTalk}/>
				<section>
					<span className="t1">
						I build interactive web applications inspired by the process of creative
						coding and problem solving. I enjoy working collaboratively on problems 
						that require both human and technological solutions, balancing stories with 
						technology to create engaging experiences. 

						My <a href="/projects/">projects</a> include data visualizations, immersive worlds and time-based art.
					</span> 
				</section>
				
				<section>
					<div><span className="t1">Owen Fernley</span></div>
					<div>
						<span className="t1 none">
							<span>owen</span>
							<span>&#64;pixels</span>
							<span>andplans.</span>
							<span>com</span>
						</span>
					</div>
				</section>
			
				<section>
					<div className="row">
						<div className="col">
							<div className="pd">
								<StaticImage className="rnd" src="../images/owen_portrait.jpg" placeholder="blurred" layout="constrained" width={1000} height={1000} alt="Owen Fernley Portrait" />
							</div>
						</div>
						<div className="col">
							<div className="lft pd">
								<h2>
									What should we build next?
								</h2> 
							</div>
							<div className="social_row">
								<div className="social">
									<a href="https://twitter.com/owenfernley">
										<StaticImage src="../images/Twitter_Social_Icon_Rounded_Square_White.png" placeholder="blurred" layout="fixed" width={60} height={60} alt="Twitter" />
									</a>
								</div>
								<div className="social">
									<a href="https://www.linkedin.com/in/owen-fernley/">
										<StaticImage src="../images/In-White-128.png" placeholder="blurred" layout="fixed" width={60} height={60} alt="LinkedIn" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
}

export default About;

export const pageQuery = graphql`
  query {
    projects: allMarkdownRemark(
    	filter: {frontmatter: {template: {eq: "project"}}}
    ) {
      totalCount
    }
	talks: allMarkdownRemark(
    	filter: {frontmatter: {template: {eq: "talk"}}}
    ) {
      totalCount
    }
  }
`;